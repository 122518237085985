import Language from 'constants/Language';
import Icon from 'elements/Icon';

const Error404Page = () => {
  return (
    <div className="h-full relative mt-80 items-center flex flex-col items-end w-full">
      <div className="absolute top-1/2 left-1/2 w-1/2 sm:w-1/3 md:w-1/4 h-full z-0 flex content-center items-center transform -translate-x-1/2 -translate-y-1/2 md:-translate-y-3/4">
        <Icon icon="greenThumbsUp" className="w-full h-80" size="custom" />
      </div>
      <h2 className="font-serif font-light text-serif-40px md:text-sans-serif-65px mb-2 z-10">
        {Language.errorPage.message}
      </h2>
    </div>
  );
};

export default Error404Page;
